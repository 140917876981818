import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import Header from "../components/organisms/Header";
import Logo from "../components/atoms/Logo";
import Footer from "../components/organisms/Footer";

const DisclaimerPage = (props) => {

  const information = props.data.allMarkdownRemark.edges[0].node.html;

  return (
    <>
      <Seo title="Disclaimer" />
      <Header classes="header--hasLogo">
        <Logo link={true} classes="" />
      </Header>
      <main className="static main--hasLogo">
        <div className="container">
          <div className="information" dangerouslySetInnerHTML={{ __html: information }} />
        </div>
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query DisclaimerQuery {
    allMarkdownRemark(filter: {frontmatter: {path: {eq: "/disclaimer"}}}) {
      edges {
        node {
          frontmatter {
            path
          }
          html
        }
      }
    }
  }
`;

export default DisclaimerPage;
